const isDevEnv = process.env.NODE_ENV === "development"
const isTestEnv = process.env.NODE_ENV === "test"
const isStaging =
  process.env.NEXT_PUBLIC_SKRIBA_ENVIRONMENT === "staging"
const isPR = process.env.NEXT_PUBLIC_SKRIBA_ENVIRONMENT === "pr"
const isDevEnvironment = isDevEnv || isTestEnv || isStaging || isPR;
const stagingInkDomain = "staging.ink.skriba.ch";
const stagingAirDomain = "staging.air.skriba.ch";

export const getAirUrl = () => {
  if (process.env.NEXT_PUBLIC_AIR_PORT) {
    return `http://localhost:${process.env.NEXT_PUBLIC_AIR_PORT}/`
  }

  if (isDevEnvironment) {
    return `https://${stagingAirDomain}/`
  }

  return "https://air.skriba.ch/"
}

export const getOrgSlugFromHost = (host: string) => {
  const isDevEnvironmentHost = host.includes("localhost") || host.includes("azurewebsites") || (host.includes("ink") && host.includes("staging"));

  if (isDevEnvironmentHost && isDevEnvironment) {
    return "skriba-ink"
  }

  return host.split(".")[0]
}

export const getDomainFromHost = (host: string) => {
  if (host.includes("localhost")) {
    return stagingInkDomain
  }

  return host
}
