export enum EOrganizationTypes {
  NONE = "none",
  DEFAULT = "default",
  DEPARTMENT = "abteilung",
  DIVISION = "bereich",
  STORE = "store",
}

// REFACTOR:
// Use numbers.
export enum EGenders {
  MALE = "1",
  FEMALE = "2",
}

export const LANGUAGE_TO_CODE_MAP = {
  english: "en",
  german: "de",
  french: "fr",
  italian: "it",
} as const

export enum EUserRoles {
  USER = 2,
  HR = 3,
  SUPER_HR = 4,
  TECH_ADMIN = 5,
}

export enum ELanguages {
  EN = "en",
  DE = "de",
  FR = "fr",
  IT = "it",
}

export const LOCAL_STORAGE_KEYS = {
  FILTERS_CERTIFICATES: "SKRIBA_FILTERS_CERTIFICATES",
  VIEW_CERTIFICATES: "SKRIBA_VIEW_CERTIFICATES",
  FILTERS_EMPLOYEES: "SKRIBA_FILTERS_EMPLOYEES",
  FILTERS_USERS: "SKRIBA_FILTERS_USERS",
  FILTERS_FUNCTIONS: "SKRIBA_FILTERS_FUNCTIONS",
  SKRIBA_AUTH_TOKEN: "SKRIBA_AUTH_TOKEN",
}

export const COOKIE_KEYS = {
  SKRIBA_BACKEND_URL: "SKRIBA_BACKEND_URL",
  SKRIBA_LOGIN_WITH_CLERK: "SKRIBA_LOGIN_WITH_CLERK",
  SKRIBA_LOGIN_WITH_GOOGLE: "SKRIBA_LOGIN_WITH_GOOGLE",
  SKRIBA_LOGIN_WITH_MICROSOFT: "SKRIBA_LOGIN_WITH_MICROSOFT",
  SKRIBA_CAN_ACCESS_ORG: "SKRIBA_CAN_ACCESS_ORG",
}
