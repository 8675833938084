import axios, { AxiosRequestConfig, AxiosResponse } from "axios"
import { PartialDeep } from "type-fest"

import { TIntegrationType } from "@/components/pages/admin/integrations/types"
import { COOKIE_KEYS, LOCAL_STORAGE_KEYS } from "src/constants"
import { paths } from "types/apiSchema"

import { getAirUrl } from "./getAirUrl"

type TQueryParamsDefault = Record<string, string | number | boolean | undefined>

export type TFetchJsonOptions<TData, TQueryParams> = AxiosRequestConfig<
  PartialDeep<TData>
> & {
  body?: PartialDeep<TData>
  queryParams?: TQueryParams
  fullResponse?: boolean
  useLocalAir?: boolean
}

function getSkribaBackendUrlCookie() {
  const cookie = document.cookie.match(
    new RegExp(`${COOKIE_KEYS.SKRIBA_BACKEND_URL}=([^;]+)`)
  )?.[1]

  const fallbackBackendUrl = process.env.NEXT_PUBLIC_API_URL

  return decodeURIComponent(fallbackBackendUrl ?? cookie ?? "")
}

function getSkribaProxyCookie() {
  const cookie = document.cookie.match(
    new RegExp(`${COOKIE_KEYS.SKRIBA_LOGIN_WITH_CLERK}=([^;]+)`)
  )?.[1]
  return cookie === "true"
}

const shouldProxyRequests =
  typeof window === "undefined" ? false : getSkribaProxyCookie()

export const apiUrl =
  typeof window === "undefined" ? "" : getSkribaBackendUrlCookie()
export const localAirPort = process.env.NEXT_PUBLIC_AIR_PORT
export const isDevEnv = process.env.NODE_ENV === "development"
export const isTestEnv = process.env.NODE_ENV === "test"
export const isStaging =
  process.env.NEXT_PUBLIC_SKRIBA_ENVIRONMENT === "staging"
export const isPR = process.env.NEXT_PUBLIC_SKRIBA_ENVIRONMENT === "pr"

function createQueryString<TQueryParams>(initParams?: TQueryParams) {
  if (!initParams || Object.keys(initParams).length === 0) return ""

  const params = Object.fromEntries(
    Object.entries(initParams)
      .filter(([, value]) =>
        value === 0 || value === false ? true : Boolean(value)
      )
      .map(([key, value]) => [key, String(value)])
  )
  const query = new URLSearchParams(params).toString()

  return `?${query}`
}

type TFetchJsonReturn<TData, TFullResponse> = TFullResponse extends true
  ? AxiosResponse<TData & { Message?: string }>
  : TData & { Message?: string }

type THeaders = Record<string, string>

export type ApiUrl = keyof paths extends `/api/${infer Path}`
  ? Lowercase<Path>
  : never

// add air api urls here
export type AirApiUrl =
  | "external/add-user"
  | "external/delete-user"
  | "external/request-password-reset"
  | "api/ink-integrations"
  | `api/ink-integrations/available-fields/${TIntegrationType}`
  | "api/ink-integrations/credentials"
  | "api/ink-integrations/run-job"
  | "api/ink-integrations/legacy-import"

// Overloads to prevent using useLocalAir with non-AirApiUrls
// or the other way around
export async function fetchJson<TData, TFullResponse = false, TQueryParams = TQueryParamsDefault>(
  url: ApiUrl,
  options?: TFetchJsonOptions<TData, TQueryParams>
): Promise<TFetchJsonReturn<TData, TFullResponse>>;

export async function fetchJson<TData, TFullResponse = false, TQueryParams = TQueryParamsDefault>(
  url: AirApiUrl,
  options: TFetchJsonOptions<TData, TQueryParams> & { useLocalAir: true }
): Promise<Omit<TFetchJsonReturn<TData, TFullResponse>, 'Message'>>;

export async function fetchJson<TData, TFullResponse = false, TQueryParams = TQueryParamsDefault>(
  url: ApiUrl | AirApiUrl,
  options?: TFetchJsonOptions<TData, TQueryParams> & { useLocalAir?: boolean }
): Promise<TFetchJsonReturn<TData, TFullResponse>> {
  const headers: THeaders = {}

  if (options?.headers) {
    Object.entries(options.headers).forEach(([key, value]) => {
      if (value !== null) {
        headers[key] = String(value)
      }
    })
  }

  if (!headers["Content-Type"]) {
    headers["Content-Type"] = "application/json"
  }

  const airUrl = getAirUrl();

  const shouldUseLocalAir = !!options?.useLocalAir

  const originalUrl = `${
    shouldUseLocalAir ? airUrl : apiUrl
  }${url}${createQueryString<TQueryParams>(options?.queryParams)}`

  if (shouldProxyRequests) {
    const proxyUrl = `/api/proxy?originalUrl=${encodeURIComponent(originalUrl)}`
    const { method, ...safeOptions } = options || {}

    const response = await axios(proxyUrl, {
      method: "POST",
      withCredentials: true,
      data: {
        method: method || "GET",
        body: options?.body,
        headers: headers,
      },
      ...safeOptions,
      headers: {
        ...safeOptions.headers,
        "X-Skriba-Air": String(options?.useLocalAir ?? false),
      },
    })

    if (options?.fullResponse) {
      return response as TFetchJsonReturn<TData, TFullResponse>
    }

    return response.data as TFetchJsonReturn<TData, TFullResponse>
  } else {
    const localAuthToken = localStorage.getItem(
      LOCAL_STORAGE_KEYS.SKRIBA_AUTH_TOKEN
    )
    if (localAuthToken) {
      axios.defaults.headers.common.Authorization = `Bearer ${localAuthToken}`
    }

    const response = await axios(originalUrl, {
      method: options?.method || "GET",
      withCredentials: true,
      data:
        options?.method === "POST" || options?.method === "PUT"
          ? options?.body
          : undefined,
      headers: headers,
      ...options,
    })

    if (isDevEnv || isTestEnv) {
      const jwtToken = response.headers["x-jwt-token"] as string
      if (jwtToken && jwtToken !== localAuthToken) {
        axios.defaults.headers.common.Authorization = `Bearer ${jwtToken}`
        localStorage.setItem(LOCAL_STORAGE_KEYS.SKRIBA_AUTH_TOKEN, jwtToken)
      }
    }

    if (options?.fullResponse) {
      return response as TFetchJsonReturn<TData, TFullResponse>
    }

    return response.data as TFetchJsonReturn<TData, TFullResponse>
  }
}

function getFileName(headers: Response["headers"]): string | undefined {
  const contentDispositionHeader = headers.get("content-disposition")
  const fileName = /filename=(.+)/.exec(contentDispositionHeader ?? "")?.[1]
  return fileName ? decodeURIComponent(fileName.replaceAll('"', "")) : undefined
}

type TFetchBlobOptions<TQueryParams> = {
  method?: "POST"
  body?: FormData
  queryParams?: TQueryParams
}

type TFetchBlobReturn<TData> = TData extends Record<string, unknown> | string
  ? TData
  : {
      blob: Blob
      fileName?: string
    }

export async function fetchBlob<TData, TQueryParams = TQueryParamsDefault>(
  url: keyof paths extends `/api/${infer Path}` ? Lowercase<Path> : never,
  options: TFetchBlobOptions<TQueryParams> = {}
) {
  const originalUrl = `${apiUrl}${url}${createQueryString(options.queryParams)}`

  if (shouldProxyRequests) {
    const proxyUrl = `/api/proxy?originalUrl=${encodeURIComponent(originalUrl)}`

    const { method, ...safeOptions } = options || {}

    const headers: THeaders = {
      "Content-Type": "application/json",
    }

    const response = await fetch(proxyUrl, {
      method: "POST",
      credentials: "include",
      headers,
      body: JSON.stringify({
        method: method || "GET",
        body: options.body,
        headers,
      }),
      ...safeOptions,
    })

    if (!response.ok) {
      throw new Error(response.statusText)
    }

    if (options.method === "POST") {
      const json = (await response.json()) as TFetchBlobReturn<TData>
      return json
    }

    const blob = await response.blob()

    return {
      blob,
      fileName: getFileName(response.headers),
    } as TFetchBlobReturn<TData>
  } else {
    const response = await fetch(originalUrl, {
      method: options.method || "GET",
      credentials: "include",
      headers: {
        Authorization: axios.defaults.headers.common.Authorization as string,
      },
      body: options.method === "POST" ? options.body : undefined,
      ...options,
    })

    if (!response.ok) {
      throw new Error(response.statusText)
    }

    if (options.method === "POST") {
      const json = (await response.json()) as TFetchBlobReturn<TData>
      return json
    }

    const blob = await response.blob()

    return {
      blob,
      fileName: getFileName(response.headers),
    } as TFetchBlobReturn<TData>
  }
}

export function fetchGetUserPictureUrl({ userId }: { userId: number }) {
  if (shouldProxyRequests) {
    return `/api/proxy?originalUrl=${encodeURIComponent(
      `${apiUrl}file/getUserPicture?userId=${userId}`
    )}`
  }

  return `${apiUrl}file/getUserPicture?userId=${userId}`
}
