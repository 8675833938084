import { z } from "zod"

const FieldSchema = <T extends z.ZodTypeAny>(valueSchema: T) =>
  z.object({
    value: valueSchema,
    isCustom: z.boolean().optional(),
  })

export const MappingSchema = z.object({
  PersonnelNumber: FieldSchema(z.string()),
  Birthdate: FieldSchema(z.string()),
  FirstName: FieldSchema(z.string()),
  LastName: FieldSchema(z.string()),
  Gender: FieldSchema(z.string()),
  Hometown: FieldSchema(z.string()).optional(),
  ExitDate: FieldSchema(z.string()).optional(),
  Employment_FromDate: FieldSchema(z.string()),
  Employment_LevelOfEmployment: FieldSchema(z.string()),
  Employment_DepartmentCode: FieldSchema(z.string()),
  Employment_FunctionCode: FieldSchema(z.string()),
  Employment_EmploymentType: FieldSchema(z.string()),
  Employment_NumberOfEmployees: FieldSchema(z.string()).optional(),
})

export type TMapping = z.infer<typeof MappingSchema>
